import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'

import ContentContainer from '../UI/ContentContainer'
import TopicDescAndImg from '../UI/TopicDescAndImg'
import Benefit from '../UI/Benefit'

const YouRInControl = () => {

	const data = useStaticQuery(graphql`
    query HomePageBenefitsQuery {
      contentfulComponentBenefitsContainer(page: {eq: "Home page"}) {
        page
        benefits {
          header {
            childMarkdownRemark {
              html
            }
          }
          icon {
            gatsbyImageData(placeholder:NONE)
            title
            file {
              contentType
              url
              details {
                image {
                  height
                  width
                }
              }
            }
          }
          content {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  `)

  return (
    <ContentContainer background="var(--base-gray)">
      <Main>
        <TopicDescAndImg categoryQuery="You're in control" isForHomePage={true}/>
      </Main>

      <BenefitsWrapper>
        <Benefit page={data.contentfulComponentBenefitsContainer} />
      </BenefitsWrapper>
    </ContentContainer>
  )
}

const Main = styled.div`

  padding: 0;
  margin: 0 auto;
  max-width: 1024px;
`
const BenefitsWrapper = styled.div`
  max-width: 1024px;
  margin: 0 auto;
  @media screen and (max-width: 991px) {
    max-width: 100%;
  }

`

export default YouRInControl