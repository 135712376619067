import React from 'react'
import styled from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'
import { graphql, useStaticQuery, navigate, Link } from 'gatsby'

import { BUTTON_COLOR, BUTTON_SIZE } from '../../support'
import HomeButton from '../UI/Buttons/HomeButton'
import CMSImage from './CMSImage'

const TopicDescAndImg = ({ categoryQuery, handleClick, isForHomePage=false }) => {
  const allTopicDescAndImgCompsQuery = useStaticQuery(graphql`
    query allTopicDescAndImgCompsQuery {
      allContentfulComponentTopicDescAndImg(
        sort: { fields: order, order: ASC }
      ) {
        nodes {
          category
          title
          subtitle
          childContentfulComponentTopicDescAndImgDescriptionTextNode {
            childMarkdownRemark {
              html
            }
          }
          buttonText
          linkOnClick
          image {
            title
            gatsbyImageData(width: 420, quality: 100, layout: CONSTRAINED, formats: [AUTO, WEBP])
          }
          imageOnLeft
          iconOnTop {
            title
            gatsbyImageData(placeholder: NONE)
            file {
              contentType
              url
              details {
                image {
                  height
                  width
                }
              }
            }
          }
        }
      }
    }
  `)

  const filterItemsByQuery = () => {
    return allTopicDescAndImgCompsQuery.allContentfulComponentTopicDescAndImg.nodes.filter((item) => item.category?.includes(categoryQuery))
  }

  return filterItemsByQuery().map(
    (c, i) =>
      c && (
        <TopicDescAndImgWrapper
          imgOnLeft={c.imageOnLeft}
          key={i}
          className={`${i === 0 && 'first-item'}`}
        >
          <div className="desc">
            {c.iconOnTop && (
              <div className="icon">
                <CMSImage
                  svg={c.iconOnTop.svg}
                  gatsbyImageData={c.iconOnTop?.gatsbyImageData}
                  file={c.iconOnTop.file}
                  alt={c.iconOnTop.title}
                />
              </div>
            )}
            {
              isForHomePage ? 
                <Link to='/personal-loans'>
                  <p className="subtitle">{c.subtitle}</p>
                </Link>
              :
                <p className="subtitle">{c.subtitle}</p>
            }
            <h2>{c.title}</h2>
            <div
              dangerouslySetInnerHTML={{
                __html:
                  c.childContentfulComponentTopicDescAndImgDescriptionTextNode
                    .childMarkdownRemark.html,
              }}
            ></div>
            {c.buttonText && categoryQuery!=="SpendOneSaveOnePage" && (
              <HomeButton
                height={BUTTON_SIZE.HEIGHT_L}
                padding={BUTTON_SIZE.PADDING_L}
                fontSize={BUTTON_SIZE.FONT_SIZE_L}
                background={BUTTON_COLOR.GREEN}
                backgroundHover={BUTTON_COLOR.GREEN_HOVER}
                color={BUTTON_COLOR.TEXT_COLOR_WHITE}
                margin="0"
                text={c.buttonText}
                handleClick={
                  c.linkOnClick ? () => navigate(c.linkOnClick) : handleClick
                }
              />
            )}
          </div>
          <Image imgOnLeft={c.imageOnLeft}>
            <GatsbyImage image={c.image?.gatsbyImageData} alt={c.image.title} />
          </Image>
        </TopicDescAndImgWrapper>
      )
  )
}

const TopicDescAndImgWrapper = styled.div`
  max-width: 1232px;
  display: flex;
  margin-top: 120px;
  flex-direction: ${(props) => (props.imgOnLeft ? 'row-reverse' : 'row')};
  justify-content: space-between;
  align-items: center;
  &.first-item {
    margin-top: 0;
  }
  h2 {
    margin: 0 0 24px;
    letter-spacing: 0;
  }
  .subtitle {
    color: var(--base-green);
    font-size: 14px;
    font-family: PoppinsBold;
    margin: 14px 0 
  }
  .subtitleForHomepage {
    ${'' /* color: var(--base-green); */}
    font-size: 14px;
    font-family: PoppinsBold;
  }
  .desc {
    text-align: left;
    max-width: 700px;
  }
  @media (max-width: 991px) {
    flex-direction: column-reverse;
    justify-content: normal;
  }
  blockquote {
    margin: 0;
    p {
      font-family: QuicksandBold;
      font-size: 20px;
      background-color: #d2f2e2;
      border-radius: 20px;
      padding: 32px;
    }
  }
  .icon {
    max-width: 40px;
  }
  @media (max-width: 768px) {
    margin-top: 80px;
  }
`

const Image = styled.div`
  flex-shrink: 0;
  margin: ${(props) => (props.imgOnLeft ? '0 30px 0 0' : '0 0 0 30px')};
  img {
    width: 100%;
    height: 100%;
  }
  @media (max-width: 991px) {
    margin: 0;
    padding-bottom: 20px;
    img {
      margin: 0 auto;
    }
  }
  @media (max-width: 768px) {
    img {
      width: 80%;
      max-width: 380px;
    }
  }
`

export default TopicDescAndImg
