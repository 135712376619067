export const dontJustTakeReview = [
  {
    "@type": "Review",
    "reviewRating": {
        "@type": "Rating",
        "ratingValue": "4.7"
    },
    "author": {
      "@type": "Organization",
      "name": "Google"
    }
  },
  {
    "@type": "Review",
    "reviewRating": {
        "@type": "Rating",
        "ratingValue": "4.8"
    },
    "author": {
      "@type": "Organization",
      "name": "Product Review"
    }
  }
]

export const aggregateRating = {
  "@type": "AggregateRating",
  "ratingValue": "4.8",
  "reviewCount": "1965"
}