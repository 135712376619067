import React from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import { Link } from 'gatsby'

import Layout from '../layouts/layout'
import HomePersonalLoansMadeEasy from '../components/ForHomepage/HomePersonalLoansMadeEasy'
import EasilyCalculate from '../components/ForPersonalLoans/PersonalLoanPages/EasilyCalculate'
import HowItWorksAndCompare from '../components/UI/HowItWorksAndCompare'
import AwardWinningPLs from '../components/UI/AwardWinningPLs'
import ResourceCenter from '../components/UI/ResourceCenter'
import BeInTheKnow from '../components/UI/BeInTheKnow'
import DontJustTake from '../components/UI/DontJustTake'
import Seo from '../components/seo'
import YouRInControl from '../components/ForHomepage/YouRInControl'
import BgCircleNavy from '../assets/images/svg/bg-circle-navy.svg'
import BgCircleGreenBlue from '../assets/images/svg/bg-circle-green-blue.svg'
import { parseJson } from '../support'
import { dontJustTakeReview } from '../support/structuredDataConfig/dontJustTakeReview'

const RootIndex = ({ path }) => {
  const queriedStructuredDataConfigForHome = useStaticQuery(graphql`
    query structuredDataConfigForHomeQuery {
      contentfulComponentStructuredDataConfig (
        page: { eq: "/" }
      ) {
        page
        structuredDataConfig {
          internal {
            content
          }
        }
      }
    }
  `)
  const structuredDataFromContentful = parseJson(queriedStructuredDataConfigForHome.contentfulComponentStructuredDataConfig.structuredDataConfig?.internal?.content)
  const structuredData = { ...structuredDataFromContentful, "Review": dontJustTakeReview}
  const homePageHowAnchor = () => {
    return (
      <>
        Getting a <Link to='/personal-loans'>personal loan</Link> with SocietyOne is quick and easy.
      </>
    )
  }
  return (
    <Layout hasGetYourRate={true}>
      <Seo 
        title="Australia's Leading Digital Finance Platform"
        description="Get a simpler, faster & fairer deal on a personal loan with SocietyOne. With flexible interest rates & no ongoing fees, borrowing has never been easier."
        image 
        structuredData={structuredData}
        path={path}
      />

      <Banner>
        <p>SocietyOne app user? Access to your account has moved to the MONEYME app. Check your email for more information. <BannerLink to='/marketplace/help'>Read FAQs</BannerLink></p>
        
      </Banner>

      <HeroWrapper>
        <HomePersonalLoansMadeEasy ai={true} />
        <ImageWrapper>
					<BgCircleNavy className="small" />
					<BgCircleGreenBlue className="big" />
				</ImageWrapper>
      </HeroWrapper>
      <YouRInControl />
      <HowItWorksAndCompare subHeader={homePageHowAnchor()} hasHowItWorks={true} isForHomePage={true}/>
      <Hr/>
      <AwardWinningPLs />
      <EasilyCalculate 
        header={"Easily calculate your loan repayments"} 
        content={homePageHowAnchor()}
      />
      <BeInTheKnow />
      <DontJustTake/>
      <ResourceCenter type={"All"}/>
    </Layout>

  )
}

const Banner = styled.div`
  background-color: rgb(29 188 110);
  min-height: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  position: fixed;
  top: 70;
  z-index: 100;
  p {
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    margin: 0;
    padding: 0px;
  
  }
`
const BannerLink = styled(Link)`
  text-decoration: underline;
	&:hover {
		color: rgb(38 75 101);
    text-decoration: underline;
  };
`
const HeroWrapper = styled.div`
  position: relative;
  overflow: hidden;
`
const ImageWrapper = styled.div`
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	max-width: 1440px;
	margin-right: auto;
	margin-left: auto;

	.small {
		position: absolute;
		left: auto;
		top: 66px;
		right: 3.6vw;
		bottom: auto;
		width: 5vw;
		height: 5vw;
		max-width: 90px;
		max-height: 600px;
	}
	.big {
		position: absolute;
    left: auto;
    top: 16.6em;
    right: -10.7em;
    bottom: auto;
    width: 51vw;
    height: 51vw;
    max-height: 600px;
    max-width: 600px;
	}
  @media screen and (max-width: 767px) {
    .small {
      display: none;
    }
    .big {
      top: auto;
      right: 3.2vw;
      bottom: 20.8em;
    }
  }
`

const Hr = styled.hr`
  background-color: rgba(225 225 225);
  height: 1px;
  border: none;
  width: 98vw;
`


export default RootIndex
